@import "../styles/theme";
@import "../styles/viewports";

div.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 10; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
  padding-top: 4%; /* Location of the box */
}

div.modalContent {
  width: 565px;
  min-height: 332px;
  border: 1px solid $black;
  margin: auto;
    box-shadow: rgb(255 255 255 / 10%) 5px -10px 15px -5px;
    border: 2px double transparent !important;
    border-radius: 0.375rem !important;
    background-image: linear-gradient(#202231, #202231), linear-gradient( 
90deg, #0077cc, #660099) !important;
    background-origin: border-box !important;
    background-clip: padding-box, border-box !important;
  border-radius: 8px;
  position: relative;
  max-height: calc(100vh - 72px);
  overflow: auto;
  @include breakpoint(sm) {
    width: 90%;
    position: relative;
    right: 3%;
    max-height: calc(100vh - 36px);
  }

  .close {
    position: absolute;
    right: 0;
    color: #aaa;
    font-size: 32px;
    font-weight: bold;
    line-height: 24px;
    margin-top: 16px;
    margin-right: 24px;

    &:hover,
    &:focus {
      color: var(--text-selected);
      text-decoration: none;
      cursor: pointer;
    }
  }
}

@import "../styles/theme";
@import "../styles/viewports";

div.walletStatus {
  display: flex;
  justify-content: flex-end;
  & > button {
    color: var(--text);
    box-shadow: rgb(255 255 255 / 10%) 5px -10px 15px -5px;
    border: 1px double transparent !important;
    border-radius: 0.375rem !important;
    background-image: linear-gradient(#202231, #202231), linear-gradient(
    90deg, #0077cc, #660099) !important;
    background-origin: border-box !important;
    background-clip: padding-box, border-box !important;
    padding-left: 8px;
    padding-right: 8px;
    background: none;
    font-family: $font-family;
    font-size: 16px;
    @include breakpoint(lg) {
      font-size: 11px;
    }

    &:hover,
    &:focus,
    &:active {
    background-image: linear-gradient(#202231, #202231), linear-gradient(
    90deg, #660099, #0077cc) !important;
    color: var(--text-darker);
    }
  }
  .hasAccount {
    height: 40px;
    line-height: 40px;
    span.address {
      margin-right: 8px;
      @include breakpoint(md) {
        display: none;
      }
    }
  }
  .noAccount {
    height: 40px;
    width: 170px;
    border-radius: 8px;
    line-height: 40px;
    color: var(--text);
    font-weight: $normal;

    @include breakpoint(lg) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      line-height: 18px;
      width: 60px;
    }
    @include breakpoint(md) {
      position: relative;
      left: 20px;
    }
    &:hover,
    &:focus,
    &:active {
      outline: none;
      transform: none;
      transition: none;
      cursor: pointer;
    }
  }
}

@import "../styles/theme";

div.confirmTransaction {
  margin-top: 0;
  padding: 40px 40px 40px 40px;
  text-align: center;
  background: var(--background-darkgray);
  border-radius: 8px;

  h3 {
    font-family: $font-family-title;
    font-style: normal;
    font-weight: $bold;
    font-size: 30px;
  }

  svg {
    margin-top: 40px;
    margin-bottom: 40px;

    fill: var(--sign-graph);

    background-color: var(--background-logo);
    border-radius: 240px;
  }
  .logo{
    width: 200px;
    outline: none;
    border-color: var(--border-effect);
    box-shadow: 0 0 10px var(--border-effect);
    border-radius: 100px;
    margin-bottom: 30px;
  }
  .loadingGif {
    width: 40px;
    margin-top: 16px;
  }
}

@import "../styles/theme.scss";

.balanceContainer {
  text-align: right;
  span,
  a {
    font-size: 12px;
  }
  a {
    font-weight: 700;
    color: var(--text);
    &:hover {
      cursor: pointer;
    }
  }
}

.tokenInputContainer {
  border: 1px solid var(--outline-gray);
  border-radius: 8px;
  padding: 8px;
  display: flex;
  width: 100%;
  position: relative;
  background-color: var(--primary-background);

  .smallText {
    font-size: $smallFont;
  }

  .boldText {
    font-weight: $bold;
    line-height: 24px;
  }

  .tokenSymbolAndName {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
  }

  img {
    vertical-align: text-bottom;
    width: 24px;
    margin: auto 0;
  }
}

.inputGroup {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  cursor: default;

  input {
    cursor: default;
    outline: none;
    text-align: right;
    background-color: var(--primary-background);
    color: var(--text);
    width: 100%;
    &::placeholder {
      color: var(--text-lighter);
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      background-color: $disabled;
      border: none;
    }
  }
}

@import "../styles/theme";

div.connectWallet {
  color: var(--text);
  padding: 40px;
  background: var(--background);
  border-radius: 8px;

  & > h3 {
    font-weight: $bold;
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 16px;
    color: var(--text);
  }

  div.walletList {
    display: grid;
    grid-template-rows: auto;
    row-gap: 16px;

    margin: 0 -24px;
    padding: 32px;

    & > button {
      background: var(--background);
      color: var(--text);
      outline: none;
      border: 1px solid var(--outline);
      border-radius: 10px;
      width: 100%;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:hover {
        cursor: pointer;
        padding: 15px;
        border: 2px solid var(--text-selected);
      }
    }

    img.icon {
      width: 24px;
    }

    span {
      line-height: 24px;
      font-size: 16px;
    }
  }

  p {
    padding: 24px 32px 32px 32px;
    margin: -24px;
    background: var(--background);
    border-radius: 8px;

    a {
      color: var(--text-selected);
      font-weight: $medium;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

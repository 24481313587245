$text-red: #f53535;
$text-black: #000000;
$text-white: #ffffff;

$primary: rgba(102,0,153,1.0);
$primary40T: #{$primary}40;
$primary-blue: rgba(0,119,204,0.9);
$primary-blue-light: rgba(0,119,204,0.7);
$primary-blue-dark: rgba(0,119,204,1.0);;
$white: #ffffff;
$white30: #4c4c4c;
$black: #000000;
$black80: #333333;
$black30: #a5a5a5;
$error: #fb5a5a;
$yellow2: #ffd848;
$yellow55: #{$yellow2}8c;
$light-blue: #e8e5fb;
$dark-purple: #110040;
$blue: #2a4bff;
$indigo20T: #{$primary-blue}33;
$blue40T: #{$blue}66;
$yellow: #f2d35f;

$divider-red: #fb5a5a;

$disabled: #aaaeb2;

// Fonts
$font-family: "Source Code Pro", monospace;
$font-family-title: "Noe Display";

$light: 300;
$normal: 400;
$medium: 500;
$bold: 700;

$smallFont: 12px;

$shadow: 0px 2px 8px rgba(39, 14, 1, 0.18);
$shadow-hover: 2px 2px 12px rgba(68, 64, 64, 0.14);

@import "../styles/theme.scss";
@import "../styles/viewports";

div.withdraw {
  min-height: 100vh;
  color: var(--text);
  padding-bottom: 80px;
  box-sizing: border-box; // Make the padding included in height calculation

  .content {
    width: 888px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 20px;
    align-items: start;
    @include breakpoint(lg) {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      width: 100vw;
    }

    .infoPanels {
      margin-top: 40px;
      width: 434px;
      padding: 24px 32px;
      border: 1px solid var(--outline);
      border-radius: 10px;
      background: var(--background-element);
      box-sizing: border-box;
      @include breakpoint(lg) {
        margin: 40px auto 0;
      }
      @include breakpoint(md) {
        max-width: 90%;
      }
    }

    .left {
      width: 434px;
      @include breakpoint(lg) {
        margin: 0 auto;
        width: 100%;
      }
    }

    .form {
      width: 434px;
      border: 1px solid var(--outline);
      background: var(--background-element);
      border-radius: 10px;
      padding: 24px;
      margin-top: 40px;
      box-sizing: border-box;
      @include breakpoint(lg) {
        margin: 40px auto 0;
      }
      @include breakpoint(md) {
        max-width: 90%;
      }
    }

    .form h3 {
      font-family: $font-family-title;
      font-size: 30px;
      margin-top: 0;
      margin-bottom: 48px;
      font-weight: $bold;
      color: var(--text);
      text-align: center;
    }

    .percentage {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      span {
        font-weight: $bold;
        letter-spacing: -0.04em;
      }

      input {
        height: 32px;
        width: 112px;
        display: block;
        background: $white;
        border: none;
        border-radius: 0;

        padding-left: 16px;
        color: $text-black;
        font-size: 16px;
        font-family: $font-family;
        font-style: normal;
        font-weight: $normal;

        &:focus {
          outline: var(--outline) solid 1px;
        }
      }

      .error {
        margin-top: 8px;
        color: $error;
        font-size: 14px;
        text-align: right;
        width: 100%;
      }
    }

    div.horizontalDisplay {
      display: flex;
      gap: 8px;
      margin-bottom: 24px;
      @include breakpoint(md) {
        flex-flow: row wrap;
      }
    }
  }

  .advancedOptions {
    margin-top: 24px;
    @include breakpoint(lg) {
      margin: 12px auto 20px;
      width: 434px;
    }
    @include breakpoint(md) {
      max-width: 90%;
    }
    svg.triangle {
      margin-left: 10px;
      transform: norotate(0deg);
    }
    svg.upsideDown.triangle {
      transform: rotate(-180deg);
    }

    .divider {
      width: 100%;
      height: 1px;
      margin: 10px 0 20px 0;
      background: var(--outline);
    }

    .tableContainer {
      display: none;
    }

    div.parameter {
      margin-top: 16px;
    }
  }
  div.paramater {
    margin-top: 16px;

    & > span {
      margin-left: 8px;
      cursor: pointer;
      letter-spacing: -0.06em;
    }

    .selected {
      color: var(--text-selected);
      font-weight: $medium;
      text-decoration: underline;
    }

    > input {
      width: 64px;
      height: 24px;
      margin-left: 16px;
      border: none;
      border-radius: 0;
      outline: 1px solid var(--outline);
    }

    > input.selected {
      color: $text-black;
    }
  }

  div.transactionInfo {
    margin-top: 32px;
    display: grid;
    grid-template-rows: auto;
    row-gap: 8px;

    span.value {
      color: var(--text);
      font-weight: $bold;
    }

    span.bonus {
      color: var(--text);
    }

    span.slippage {
      color: $error;
    }
  }

  div.show {
    display: block;
  }

  .formSpace {
    width: 100%;
    height: 24px;
  }
}

div.noShare {
  background: var(--background);
}

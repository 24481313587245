@import "../styles/theme";

.virtualSwapModal {
  background: var(--primary-background);
  display: flex;
  flex-direction: column;
  height: 100%;
  .headerContent {
    padding: 24px;
    color: $primary;
  }
  .centerContent {
    padding: 24px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .timer > h2 {
      font-size: 30px;
    }
  }
  .footerContent {
    padding: 24px;
    background: var(--primary-foreground);
    flex: 1;
    color: grey;
    b.title {
      color: var(--primary-background);
    }
    .about {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: var(--primary-background);
      a {
        margin-left: 8px;
        text-decoration: underline;
        cursor: pointer;
      }
      > svg {
        fill: var(--primary-background);
      }
    }
  }
}

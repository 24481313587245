@import "../styles/theme";

div.poolInfoCard {
  color: var(--text);
  font-size: 16px;
  font-weight: $normal;

  h4 {
    width: 100%;
    font-family: $font-family-title;
    margin-top: 24px;
    margin-right: 0;
    margin-bottom: 24px;
    font-size: 30px;
    font-weight: $bold;
    color: var(--text-title);
  }

  div.info {
    display: grid;
    grid-template-rows: auto;
    row-gap: 10px;
    .label {
      margin-right: 8px;
    }
    .underline {
      border-bottom: 1px dotted var(--text);

      &:hover {
        cursor: help;
      }
    }
  }

  .bottom {
    h4 {
      margin-bottom: 4px;
    }
  }

  .twoColumn {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;

    span {
      display: block;
      line-height: 20px;
    }
  }

  .tokenList {
    margin-top: 8px;

    display: flex;
    flex-wrap: wrap;

    img {
      vertical-align: text-bottom;
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }

    .token {
      margin-top: 24px;

      &:nth-child(odd) {
        width: 51%;
      }
    }

    span.tokenPercent {
      margin-left: 16px;
      font-weight: $medium;
    }

    span.tokenValue {
      display: block;
      margin-top: 8px;
    }
  }

  .bold {
    font-weight: $bold;
  }
}

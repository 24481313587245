@import "../styles/theme";

button.button {
  border-radius: 4px;
  border: none;
  color: $text-white;
  font-weight: $bold;
  font-size: 16px;
  letter-spacing: 0.02em;
  cursor: pointer;
  padding: 1px 6px;

  &:disabled {
    cursor: not-allowed;
    color: var(--text-lightest);
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }

  &.primary {
    background: $primary;
    box-shadow: rgb(255 255 255 / 10%) 5px -10px 15px -5px;
    border: 1px double transparent !important;
    border-radius: 0.375rem !important;
    background-image: linear-gradient(#202231, #202231), linear-gradient(
    90deg, #0077cc, #660099) !important;
    background-origin: border-box !important;
    background-clip: padding-box, border-box !important;
    &:enabled:hover,
    &:enabled:focus {
      background-color: $primary;
      color: $text-white;
      box-shadow: var(--shadow-hover);
    }
  }

  &.secondary {
    box-shadow: rgb(255 255 255 / 10%) 5px -10px 15px -5px;
    border: 1px double transparent !important;
    border-radius: 0.375rem !important;
    background-image: linear-gradient(#383c59, #484d73), linear-gradient(
    90deg, #0077cc, #660099) !important;
    background-origin: border-box !important;
    background-clip: padding-box, border-box !important;

    &:disabled {
      cursor: not-allowed;
      color: var(--text-secondary-button-disabled);
    }

    &:enabled:hover,
    &:enabled:focus {
      background-color: $primary;
      color: $text-white;
      font-weight: $bold;
      box-shadow: var(--shadow-hover);
    }
  }

  &.ternary {
    color: $white;
    background: $divider-red;
    font-weight: $medium;
    letter-spacing: -0.06em;
  }

  &.temporary {
    background: $yellow;
    color: $black;

    &:disabled {
      cursor: not-allowed;
      color: rgba(0, 0, 0, 0.2);
    }

    &:enabled:hover,
    &:enabled:focus {
      background-color: $primary;
      color: $text-white;
      box-shadow: var(--shadow-hover);
    }
  }

  &.cancel {
    box-shadow: rgb(255 255 255 / 10%) 5px -10px 15px -5px;
    border: 1px double transparent !important;
    border-radius: 0.375rem !important;
    background-image: linear-gradient(#383c59, #484d73), linear-gradient(
    90deg, #0077cc, #660099) !important;
    background-origin: border-box !important;
    background-clip: padding-box, border-box !important;
    &:enabled:hover,
    &:enabled:focus {
      color: var(--text-lightest);
    }
  }

  &.large {
    width: 176px;
    min-height: 40px;
  }
}

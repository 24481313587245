@import "../styles/theme";
@import "../styles/viewports";

header.top {
  height: 88px;
  padding-left: 56px;
  padding-right: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include breakpoint(sm) {
    padding-left: 56px;
    padding-right: 70px;
  }

  .topNavMoreButton{
    border:none;
    opacity: 1;
    border-radius: 8px;
    padding-left: 12px;
    margin-top: -5px;
    span{
      color: var(--text-lighter);
      font-size: 16px;
      text-decoration: none;
      font-style: normal;
      font-weight: 400;
    }
    &:hover{
      background: var(--text-darker);
      span{
        color: var(--text);
      }
    }
    &[data-active]{
      background: var(--text-darker);
    }
  }

  h1 {
    width: 20%;
    margin: 0;
    margin-right: auto;
    font-size: 30px;
    .logo {
      outline: none;
      border-color: var(--border-effect);
      box-shadow: 0 0 10px var(--border-effect);
      width:62px;
      border-radius: 52px;
      display: inline-block;
      margin-right: 0.4em;
      @include breakpoint(md) {
        margin-right: 0.2em;
      }
      &:hover {
        cursor: pointer;
        border-color: #0077cc;
        box-shadow: 0 0 10px #0077cc;
      }
    }
  }

  ul.nav {
    font-family: $font-family;
    list-style-type: none;
    padding: 0;
    margin: 0;
    .chakra-menu__menuitem{
      &:hover{
        background: var(--text-darker);
      }
    }
    .chakra-menu__menu-list{
      background: var(--background-darkgrey);
    }
    & > li {
      display: inline;
      margin-right: 40px;
      @include breakpoint(lg) {
        margin-right:24px;
      }
      @include breakpoint(md) {
        margin-right: 18px;
      }
      @include breakpoint(sm) {
        margin-right: 8px;
      }
      @include breakpoint(xs) {
        margin-right: 4px;
      }
      &:last-child {
        margin-right: 0;
      }
      a {
        color: var(--text);
        padding: 12px;
        text-decoration: none;
        font-style: normal;
        font-weight: $normal;
        font-size: 20px;
        color: var(--text-lighter);
        @include breakpoint(lg) {
          font-size: 18px;
        }
        @include breakpoint(md) {
          font-size: 16px;
        }
        @include breakpoint(sm) {
          font-size: 14px;
        }
        &:hover {
          opacity: 1;
          background: var(--text-darker);
          border-radius: 8px;
          color: var(--text);
        }

        &.active {
          color: var(--text);
          opacity: 1;
          background: var(--text-darker);

          border-radius: 8px;
          font-weight: $bold;
        }
      }
    }
  }
  .walletStatus {
    margin-left: auto;
    width: 20%;

    .connection {
      // padding: 8px;
      // border: 1px solid;
      // border-radius: 10px;
      &.success .indicator {
        filter: invert(1);
      }
    }
    img.logo {
      height: 1.5em;
    }
  }
}

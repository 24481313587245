/**
* GLOBAL STYLES
* Imported once at the top level
* Used everywhere
*/
@import "fonts";
@import "theme";

* {
  box-sizing: border-box;
}

p,
b,
span {
  font-family: $font-family;
  line-height: 20px;
  size: 16px;
  color: var(--text);
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $font-family-title;
  color: var(--text);
}

svg,
img {
  // TODO: get rid of this once chakra is more integrated
  display: inline !important;
}

ul {
  list-style: none;
}

.content .swapForm{
      box-shadow: rgb(255 255 255 / 10%) 5px -10px 15px -5px;
    border: 1px double transparent !important;
    border-radius: 0.375rem !important;
    background-image: linear-gradient(rgb(32, 34, 49), rgb(32, 34, 49)), linear-gradient(
90deg, rgb(0,119,204), rgba(102,0,153,1.0)) !important;
    background-origin: border-box !important;
    background-clip: padding-box, border-box !important;
}
.gradient-border {
  --border-width: 2px;
  position: relative;
  color: white;
  background: #222;
  border-radius: var(--border-width);

  &::after {
    position: absolute;
    content: "";
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    z-index: -1;
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(
      60deg,
      hsl(205, 97%, 47%),
      hsl(280, 98%, 43%),
      hsl(195, 56%, 59%),
      hsl(250, 52%, 65%),
      hsl(205, 100%, 10%),
      hsl(280, 80%, 15%),
      hsl(204, 90%, 12%),
      hsl(275, 20%, 88%),
    );
    background-size: 300% 300%;
    background-position: 0 50%;
    border-radius: calc(2 * var(--border-width));
    animation: moveGradient 4s alternate infinite;
  }
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}
.background-wrapper{
    background: #000 url("../assets/bg.svg") no-repeat top center;
    background-size: contain;
    max-width: 100vw;
    min-width: 768px;
    width: 100%;
    height: auto;
    top: 0px;
    left: 0px;
    right: 0px;
    opacity: 0.92;
    margin: 0 auto;
}

:root {
  --primary-foreground: rgba(12,10,29,1.0);
  --primary-background: rgb(38, 39, 43);
  --background: rgb(26, 27, 32);
  --background-lightgrey: rgba(12,10,29,0.4);
  --background-darkgrey: rgba(12,10,29,0.9);
  --background-element: rgb(19, 20, 25);
  --background-element-hover: rgba(255, 255, 255, 0.88);
  --background-logo: #{$primary};
  --secondary-button: #{$light-blue};
  --outline: rgb(19, 20, 25);
  --outline-gray: #{$black30};
  --option-hover: #{$primary-blue-light};
  --tab: #{$black80};

  --border-effect: #9ecaed;
  --text: rgba(255,255,255,0.88);
  --text-darker: #000;
  --text-lightest: rgba(255,255,255,0.72);
  --text-lighter: rgba(255,255,255,0.52);
  --text-title: #{text-black};
  --text-primary: #{$primary};
  --text-selected: #{$primary};
  --text-secondary-button: #{$primary-blue};
  --text-secondary-button-disabled: #{$indigo20T};
  --header-text: #1f2023;
  --sign-graph: #000;

  --shadow: 0px 2px 8px rgba(39, 14, 1, 0.1);
  --shadow-hover: 2px 2px 12px rgba(68, 64, 64, 0.2);
}

// Dark mode themes
body.dark {
  --primary-foreground: #fff;
  --primary-background: #000;
  --background: #000;
  --background-lightgrey: #{$black80};
  --background-element: rgba(0, 0, 0, 0.56);
  --background-element-hover: rgba(0, 0, 0, 0.8);
  --background-main: #000 url("../assets/bg.svg") center/auto 60% no-repeat
    fixed;
  --background-logo: #ffffe9;
  --secondary-button: #{$dark-purple};
  --outline: #fff;
  --option-hover: #{$primary-blue-dark};
  --outline-gray: #{$white30};
  --tab: #{$white};

  --text: #fff;
  --text-darker: rgba(88,47,213,1);
  --text-lighter: #bbbbbb;
  --text-lightest: #888888;
  --text-title: #ffffe9;
  --text-primary: #{$primary};
  --text-selected: #{$primary};
  --text-secondary-button: #{$blue};
  --text-secondary-button-disabled: #{$blue40T};
  --header-text: #{$primary};
  --sign-graph: #{$primary-blue};

  --shadow: 0px 2px 8px rgba(39, 14, 1, 0.18);
  --shadow-hover: 2px 2px 12px rgba(68, 64, 64, 0.14);
}

/* glass orb */
.orb {
	position: relative;
	border-radius: 50%;
	margin: 0 5em;
	background: #C0FFE4;
	background-image: radial-gradient(farthest-corner at 34px 14px, #9ecaed 25%, #689cc7 35%,  #74b5eb 100%);
	box-shadow: 0 0 20px 5px #C0FFE4;
	animation: orb 1.8s infinite;
}
.orb:before {
	content: '';
	display: block;
	position: absolute;
	width: 44px;
	height: 44px;
	top: 2px;
	left: 2px;
	background-image: radial-gradient(farthest-corner at 14px 14px, #fff 15%, #689cc7 50%, rgba(192,255,228,0) 100%);
	border-radius: 50%;
}
/* animates orb glow */
@keyframes orb {
	0%,100% {
		box-shadow: 0 0 5px 2px #9ecaed;
	}
	50% {
		box-shadow: 0 0 24px 4px #9ecaed;
	}
}
@import "../styles/theme";
@import "../styles/viewports";

div.poolOverview {
  margin-top: 40px;
  width: 100%;
    box-shadow: rgb(255 255 255 / 10%) 5px -10px 15px -5px;
    border: 1px double transparent !important;
    border-radius: 0.375rem !important;
    background-image: linear-gradient(#202231, #202231), linear-gradient(
    90deg, #0077cc, #660099) !important;
    background-origin: border-box !important;
    background-clip: padding-box, border-box !important;
  border-radius: 10px;
  padding: 24px 32px;
  display: grid;
  grid-template-columns: 45% 55%;
  &:hover{
        background-image: linear-gradient(#202231, #202231), linear-gradient(
    90deg, #660099, #0077cc) !important;
  }
  @include breakpoint(md) {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    row-gap: 24px;
    padding: 24px 6%;
    width: 80%;
  }
  @include breakpoint(sm) {
    width: 90%;
    padding: 24px 2%;
  }
  @include breakpoint(xs) {
    width: 100%;
    padding: 24px 2%;
  }

  .left {
    display: grid;
    grid-template-rows: 40px 24px 22px 10px 20px;
  }

  .titleAndTag {
    grid-row: 1/2;
    display: flex;
  }

  h4.title {
    font-family: $font-family-title;
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 30px;
    font-weight: $bold;
    color: var(--text-title);
    margin-right: 16px;
  }

  .balance {
    grid-row: 3/4;
  }
  .tokens {
    grid-row: 5/6;
    display: flex;
    flex-direction: row;
    @include breakpoint(md) {
      width: 100%;
      justify-content: space-between;
    }
  }

  div.token {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }

    & > img {
      margin-right: 4px;
      vertical-align: sub;
      height: 18px;
      width: 18px;
    }
  }

  div.right {
    display: grid;
    justify-content: end;
    @include breakpoint(md) {
      justify-content: space-between;
      row-gap: 24px;
      grid-template-columns: 100%;
    }

    .margin {
      margin-right: 24px;
      @include breakpoint(md) {
        margin-right: auto;
      }
    }
    .poolInfo {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      justify-self: end;
      @include breakpoint(md) {
        justify-self: start;
        width: 100%;
        justify-content: space-between;
      }
    }
    span.label {
      display: block;
      margin-bottom: 4px;
      font-weight: $bold;
    }

    .plus {
      font-weight: $medium;
      color: var(--text-selected);
      display: block;
    }
    .minus {
      font-weight: $medium;
      color: $error;
    }

    .buttons {
      align-self: end;
      justify-self: end;
      display: grid;
      grid-template-columns: 176px 176px;
      column-gap: 16px;
      @include breakpoint(md) {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }
  }
  .label.underline {
    border-bottom: 1px dotted var(--text);
  }
}

div.outdated {
  border-color: $yellow2;
}

.tag {
  border-radius: 4px;
  font-size: 20px;
  font-weight: $bold;
  align-self: center;
  padding: 2px 4px;
}
.tag.warning {
  border: 1px solid $yellow2;
  color: $yellow2;
}
.tag.error {
  border: 1px solid $error;
  color: $error;
}

div.poolOverview:first-child {
  margin-top: 0;
}

@import "../styles/theme";
@import "../styles/viewports";

div.poolsPage {
  min-height: 100vh;
  color: var(--text);
  font-size: 16px;
  padding-bottom: 80px;

  .filters {
    width: max-content;
    margin: 24px auto;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    column-gap: 16px;
  }

  .filterTab {
    width: max-content;
    padding: 2px 16px;
    box-shadow: rgb(255 255 255 / 10%) 5px -10px 15px -5px;
    border: 1px double transparent !important;
    border-radius: 0.375rem !important;
    background-image: linear-gradient(#202231, #202231), linear-gradient(
    90deg, #0077cc, #660099) !important;
    background-origin: border-box !important;
    background-clip: padding-box, border-box !important;
    cursor: pointer;
    font-weight: $medium;

    &.selected {
      background-color: var(--tab);
      color: var(--text);
      font-weight: $bold;
    }
  }

  .outdated {
    color: $yellow;

    &.selected {
      background-color: $yellow;
      color: $text-black;
    }
  }

  .content {
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include breakpoint(sm) {
      width: 100%;
    }
  }
}

@import "../styles/theme.scss";
@import "../styles/viewports";

.advancedOptions {
  width: 100%;
  margin-top: 24px;
  @include breakpoint(lg) {
    margin: 12px auto 20px;
    width: 434px;
  }
  @include breakpoint(md) {
    max-width: 90%;
  }
  svg.triangle {
    margin-left: 10px;
    transform: norotate(0deg);
  }
  svg.upsideDown.triangle {
    transform: rotate(-180deg);
  }

  .title {
    cursor: pointer;
  }

  .divider {
    width: 100%;
    height: 1px;
    margin: 10px 0 20px 0;
    background: var(--outline);
  }

  .tableContainer {
    display: none;
  }

  div.parameter {
    margin-top: 16px;
  }

  .show {
    display: block;
  }

  .parameter {
    margin: 2px 0;
  }
  .parameter .options {
    margin-top: 10px;
  }

  div.inputGroup {
    input {
      width: 48px;
      border: 1px solid $black;
      border-radius: 8px;
      height: 28px;
      font-size: 80%;
      color: var(--text);
      padding: 2px 4px;
      margin: 0 6px 0 8px;
      outline: none !important;
      background: var(--text-lighter);
    }

    input:focus {
      outline: 1px solid var(--outline);
    }

    .label {
      font-weight: $bold;
      font-size: 16px;
      letter-spacing: -0.04em;
      margin: auto 16px auto 0;
      @include breakpoint(lg) {
        font-size: 13px;
        margin-right: 0px;
        width: 90px;
      }
      @include breakpoint(sm) {
        font-size: 13px;
        margin-right: 0px;
        width: 80px;
      }
    }
    .options {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      @include breakpoint(lg) {
        font-size: 12px;
      }
      @include breakpoint(md) {
        font-size: 11px;
      }
    }

    .options > button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      border-radius: 8px;
      background: none;
      font-size: 16px;
      width: 80px;
      font-weight: $normal;
      color: var(--text);
      letter-spacing: -0.04em;
      padding: 4px 10px;
      cursor: pointer;

      &:hover {
        transform: none;
        font-weight: $bold;
      }

      &:focus {
        outline: none;
        border: none;
      }

      &.selected {
        color: var(--text-selected);
        font-weight: $medium;
        border: 1px solid var(--text-selected);
        border-radius: 8px;
      }
      @include breakpoint(lg) {
        font-size: 12px;
        width: 72px;
        height: 28px;
      }
      @include breakpoint(md) {
        font-size: 11px;
        width: 72px;
        height: 28px;
      }
      @include breakpoint(xs) {
        font-size: 11px;
        width: 58px;
        height: 28px;
        padding: 0 2px;
        margin-right: 2px;
      }
    }
  }
}

.infiniteApproval {
  position: relative;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;

  .label {
    position: relative;
    border-bottom: 1px dotted var(--text);
    font-weight: $bold;
    font-size: 16px;
    letter-spacing: -0.04em;
    margin-left: 16px;

    &:hover {
      cursor: help;
    }
  }
}

@import "../styles/theme.scss";
@import "../styles/viewports";

div.deposit {
  min-height: 100vh;
  color: var(--text);
  padding-bottom: 80px;
  box-sizing: border-box; // Make the padding included in height calculation
  width: 100vw;

  .content {
    width: 888px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 20px;
    align-items: start;
    @include breakpoint(lg) {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      width: 100vw;
    }

    .infoPanels {
      margin-top: 40px;
      width: 434px;
      padding: 24px 32px;
      border: 1px solid var(--outline);
      border-radius: 10px;
      background: var(--background-element);
      box-sizing: border-box;
      @include breakpoint(lg) {
        margin: 40px auto 0;
      }
      @include breakpoint(md) {
        max-width: 90%;
      }
    }

    .left {
      width: 434px;
      @include breakpoint(lg) {
        margin: 0 auto;
        width: 100%;
      }
    }

    .form {
      width: 434px;
      border: 1px solid var(--outline);
      background: var(--background-element);
      border-radius: 10px;
      padding: 24px;
      margin-top: 40px;
      box-sizing: border-box;
      @include breakpoint(lg) {
        margin: 40px auto 0;
      }
      @include breakpoint(md) {
        max-width: 90%;
      }
    }

    .form h3 {
      font-family: $font-family-title;
      font-size: 30px;
      margin-top: 0;
      margin-bottom: 48px;
      font-weight: $bold;
      color: var(--text);
      text-align: center;
    }

    .infoPanels .divider {
      width: 100%;
      height: 1px;
      background: #0271BF;
      margin: 16px 0;
    }
  }

  div.wrappedDeposit {
    display: flex;
    align-items: center;
    margin-top: 32px;
    > span {
      margin-left: 8px;
    }
  }

  div.transactionInfo {
    margin-top: 32px;
    display: grid;
    grid-template-rows: auto;
    row-gap: 8px;

    span.value {
      color: var(--text);
      font-weight: $bold;
    }

    span.bonus {
      color: var(--text);
    }

    span.slippage {
      color: $error;
    }

    a {
      color: var(--text-selected);
    }
  }

  .error {
    width: 100%;
    color: $error;
    font-weight: $normal;
    text-align: center;
    margin: 8px;
  }

  .formSpace {
    width: 100%;
    height: 16px;
  }
}
